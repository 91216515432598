<!--会员等级-->
<template>
    <div class="mainBox">
        <!--场景搜索-->
        <div class="selectionBar">
            <div class="flex flex-ai-c flex-jc-sb flex-wrap">
                <label>场景：</label>
                <el-input placeholder="请输入会员名称" v-model="select.name" size="small" style="width:300px;margin-left:10px" @change="curr=1;getList();">
                    <el-button slot="append" icon="el-icon-search" style="background：#fff"></el-button>
                </el-input>
                <el-button type="primary" block size="small" icon="el-icon-plus" @click="edit()">新建会员等级</el-button>
            </div>
        </div>
        <!--主要内容-->
        <div class="table" style="height:calc( 100% - 175px )">
            <el-table :data="list"  stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' height="calc( 100% - 41px )">
                <el-table-column prop="name" label="会员名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="name" label="会员条件" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <p v-if="scope.row.cost_first!=0">首购满{{scope.row.cost_first}}元</p>
                        <p v-if="scope.row.cost!=0">累计消费{{scope.row.cost}}元</p>
                        <p v-if="scope.row.recharge!=0">充值{{scope.row.recharge}}元</p>
                    </template>
                </el-table-column>
                <el-table-column prop="content" label="会员权益" show-overflow-tooltip></el-table-column>
                <el-table-column prop="limit_time" align="center" label="有效期（月）" show-overflow-tooltip></el-table-column>
                <el-table-column prop="create_time" label="创建时间" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" align="center" fixed="right" width="240">
                    <template slot-scope="scope">
                        <el-button type="success" plain size="mini" @click="edit(scope.row)">编辑</el-button>
                        <el-button type="danger" plain size="mini" @click="deletes(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>

        <!-- 新建框 -->
        <el-dialog :title="title" custom-class="way" :visible.sync="dialogFormVisible" :modal-append-to-body="false" :close-on-click-modal="false" width="700px">
            <el-form ref="formAuth" :rules="formRules" :model="formData"  size="small" class="alertForm">
                <el-form-item label="会员名称" prop="name" >
                    <el-input v-model="formData.name" placeholder="会员名称" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="会员到期时间" prop="limit_time">
                    <el-input placeholder="会员到期时间" v-model="formData.limit_time" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,1})?/)" autocomplete="off">
                        <template slot="append">月</template>
                    </el-input>
                    <!-- <el-select v-model="formData.limit_time" placeholder="选择到期时间" clearable style="width:100%">
                        <el-option v-for="item in selectData" :label="item.name" :value="item.name" :key="item.name"></el-option>
                    </el-select> -->
                </el-form-item>
                <div style="width:100%;margin:10px 0 20px;color:#F56C6C"><span style="margin-right:5px">*</span>设置成为会员条件（满足一个即可）</div>
                <el-form-item label="新用户首购满" style="width:100%">
                    <el-input placeholder="设置新用户首购金额" v-model="formData.cost_first" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" autocomplete="off">
                        <template slot="append">元</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="累计消费满" style="width:100%;">
                    <el-input placeholder="累计消费" v-model="formData.cost" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" autocomplete="off">
                        <template slot="append">元</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="用户充值充" style="width:100%">
                    <el-input placeholder="充值金额" v-model="formData.recharge" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" autocomplete="off">
                        <template slot="append">元</template>
                    </el-input>
                </el-form-item>
                
                <!-- <el-form-item label="权重" style="width:100%">
                    <el-input v-model="formData.name" placeholder="会员名称" autocomplete="off"></el-input>
                </el-form-item> -->
                <el-form-item label="会员权益" style="width:100%">
                    <el-input type="textarea" placeholder="会员权益" :rows="3" resize="none" v-model="formData.content"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="submit()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import {memberLevelListApi,memberLevelEditApi,memberLevelDeleteApi}  from '@/api/config.js';
    export default {
        data() {
            return {
                select:{},
                list:[], //商品列表
                formData:{},
                formRules:{
                    name: [{
                        required: true,
                        message: '请输入会员名称',
                        trigger: 'change'
                    }],
                    limit_time:[{
                        required: true,
                        message: '请选择到期时间',
                        trigger: 'change'
                    }]
                },
                

                title:'新建会员等级',

                curr: 1, //页码
                row: 10, //每页条数
                pages: 1, //总页数
                count: 0, //总条数

                dialogFormVisible:false
            }
        },
        mounted() {
            this.getList();
        },
        methods: {
            //商品列表
            getList: function(){
                memberLevelListApi({
                    row:this.row,
                    curr:this.curr,
                    ...this.select
                }).then(response=>{
                    this.list = response.list;
                    this.curr = response.curr;
                    this.pages = response.pages;
                    this.count = response.count;
                })
            },
            //编辑配送人员信息
            edit: function(param = {}){
                this.dialogFormVisible = true;
                if(typeof param.id == 'undefined'){
                    this.title = '新建会员等级';
                    this.formData = {
                        time_id:6
                    };
                }else{
                    this.title = '编辑会员等级';
                    this.formData = JSON.parse(JSON.stringify(param));
                }
            },

            //删除配送人员
            deletes: function(id){
                this.$confirm('此操作将永久删除该文件, 是否继续', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    memberLevelDeleteApi({
                        id:id
                    }).then(()=>{
                        this.getList();
                        this.$message({
                            type: 'success',
                            message: '已删除!'
                        });
                    })
                }).catch(()=>{})
            },

            submit: function(){
                this.$refs['formAuth'].validate((valid) => {
                    if (valid) {
                        if(!this.formData.cost_first && !this.formData.cost && !this.formData.recharge){
                            this.$message({
                                type: 'error',
                                message: '会员条件至少设置一个必填!'
                            });
                            return;
                        }
                        if(this.formData.cost_first == 0 && this.formData.cost == 0 && this.formData.recharge==0){
                            this.$message({
                                type: 'error',
                                message: '会员条件不得设置为0!'
                            });
                            return;
                        }
                        memberLevelEditApi(this.formData).then(()=>{
                            this.closeDialog();
                            this.getList();
                            this.$message({
                                type: 'success',
                                message: '保存成功!'
                            });
                        })
                    }
                })
            },

            closeDialog:function(){
                this.$refs['formAuth'].resetFields();
                this.dialogFormVisible = false;
            },
            //翻页
            currentChange: function(curr) {
                this.curr = curr;
                this.getList();
            },
            //改变每页条数
            sizeChange: function(row) {
                this.row = row;
                this.getList();
            },
        },
    }
</script>